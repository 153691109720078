import {
  Autocomplete,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import style from "../../Styles/salaries.module.css";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { urlEnum } from "../../Utils/urlEnum";
import { getData } from "../../Services/getData";
import TableHead from "@mui/material/TableHead";
import { CacheContext } from "../../Context/cacheContext";
import { updateData } from "../../Services/updateData";
import { postData } from "../../Services/postData";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { download } from "../../Utils/utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserData } from "../Users/UsersDashboard";
import SalaryTotals from "./SalaryTotals";
import UserBonuses from "./UserBonuses";
import VehiclesForMarketingTable from "./VehiclesForMarketingTable";
import VehiclesTable from "./VehiclesTable";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { RolesEnum } from "../../Utils/Constants";
import { NumericFormatCustom } from "../../Utils/NumericFormatComponent";
import withRole from "../../Utils/withRole";

function SalaryComponent(props: any) {
  const [soldVehicles, setSoldVehicles] = useState({
    negativeProfitVehicles: [],
    negativeProfitPage: 0,
    negativeProfitPerPage: 10,
    nrOfNegativeVehicles: 0,
    positiveProfitVehicles: [],
    positiveProfitPerPage: 10,
    positiveProfitPage: 0,
    nrOfPositiveVehicles: 0,
  });
  const [state, setState] = useState({
    vehiclesSold: [],
    vehiclesSoldPage: 0,
    vehiclesSoldPerPage: 10,
    otherVehicles: [],
    otherVehiclesNumber: 0,
    otherVehiclesPage: 0,
    otherVehiclesPerPage: 10,
    targetVolum: 0,
    comisionProfit: "",
    comisionCA: "",
    vehiclesTotalCommission: "",
    generalTax: "",
    sellingLogisticsCommission: 0,
    isMarketing: false,
    monthlyProfit: 0,
    vehiclesPurchaseTypeCommission: 0,
    intermediatedVehiclesCommission: 0,
    sellingCommission: 0,
    totalNegativeProfit: 0,
  });
  const [userData, setUserData] = useState({
    leasingBonus: "",
    otherBonuses: "",
    mealTickets: "",
    profitFromOtherUsers: 0,
    baseNetSalary: 0,
    isSubcontractor: false,
  });

  const months = Array.from({ length: 12 }, (e, i) =>
    new Date(0, i).toLocaleString("ro", { month: "long" })
  );
  const cacheContext: any = useContext(CacheContext);
  const desktop = useMediaQuery("(min-width:1320px)");
  const years = cacheContext.cache?.years;
  const urlParams = useParams();
  const urlUserId = urlParams?.id;
  const month = urlParams?.month
    ? parseInt(urlParams?.month)
    : new Date().getMonth() + 1;
  const year = urlParams?.year
    ? parseInt(urlParams?.year)
    : new Date().getFullYear();
  const [historyData, setHistoryData] = useState({
    year: year,
    month: month,
  });
  const userId = urlUserId ? urlUserId : window.localStorage.getItem("useId");
  const user = cacheContext.cache.users?.find((user: any) => user.id == userId);
  const settings = Object.assign(cacheContext.cache.settings);
  const navigate = useNavigate();

  const trimesterSettings = settings
    .filter(
      (
        setting: typeof settings //filter settings and retrieve only trimesters settings
      ) => setting.slug.includes("trimester")
    )
    ?.map((setting: typeof settings) => JSON.parse(setting.value));

  const commisionsProcent = settings //filter settings and retrieve only comisions settings
    .filter((setting: typeof settings) =>
      setting.slug.includes("ComisionTarget")
    )
    .map((setting: typeof settings) => {
      return { [setting.slug]: setting.value };
    })
    .reduce((accumulator: any, currentObject: any) => {
      return { ...accumulator, ...currentObject };
    }, {});
  const currentTrimester = Math.ceil(month / 3);
  const numberFormat = new Intl.NumberFormat();
  const location = useLocation();
  const rows = [
    {
      key: "targetVolum",
      label: Vocabulary.targetVolum,
      targetCode: "ComisionTargetVolum",
    },
    {
      key: "comisionCA",
      label: Vocabulary.CATarget,
      targetCode: "ComisionTargetCA",
    },
    {
      key: "comisionProfit",
      label: Vocabulary.profitTarget,
      targetCode: "ComisionTargetProfit",
    },
  ];

  const [totalCommissions, setTotalCommissions] = useState({
    totalBrutCommission: 0,
    totalNetCommission: "",
  });

  const roles = localStorage.getItem("roles") || "";
  const isAdmin = roles.includes(RolesEnum?.admin);
  const [editMode, setEditMode] = useState(false);
  const [trimesterData, setTrimesterData] = useState(
    trimesterSettings[currentTrimester - 1]
  );
  const [profitFromOtherUsers, setProfitFromOtherUsers] = useState(0);
  const [totalMarketingCommission, setTotalMarketingCommission] = useState(0);

  /**
   *
   */
  useEffect(() => {
    getVehicles();
    getUserData();
  }, [location]);

  /**
   *
   * @param newFilters
   */
  async function getVehicles(
    positiveProfitPage = soldVehicles.positiveProfitPage,
    positiveProfitPerPage = soldVehicles.positiveProfitPerPage,
    negativeProfitPage = soldVehicles.negativeProfitPage,
    negativeProfitPerPage = soldVehicles.negativeProfitPerPage,
    otherVehiclesPage = state?.otherVehiclesPage,
    otherVehiclesPerPage = state.otherVehiclesPerPage
  ) {
    const url = `${urlEnum.soldVehiclesForUser}/${userId}/${historyData.month}/${historyData.year}/${positiveProfitPage}/${positiveProfitPerPage}/${negativeProfitPage}/${negativeProfitPerPage}/${otherVehiclesPage}/${otherVehiclesPerPage}`;

    const res = await getData(url);
    if (res?.data) {
      setState({
        ...state,
        otherVehicles: res.data[0]?.vehiclesAchizitieLogistica,
        targetVolum: res.data[0]?.targetVolum,
        comisionProfit: res.data[0]?.comisionProfit,
        comisionCA: res.data[0]?.comisionCA,
        otherVehiclesPage: otherVehiclesPage,
        otherVehiclesPerPage: otherVehiclesPerPage,
        otherVehiclesNumber: res.data[0]?.numberOfOtherVehicles,
        vehiclesTotalCommission: res.data[0]?.vehiclesTotalCommission,
        generalTax: res.data[0]?.generalTax,
        sellingLogisticsCommission: res.data[0]?.sellingLogisticsCommission,
        isMarketing: res.data[0]?.isMarketing,
        monthlyProfit: res.data[0]?.monthlyProfit,
        vehiclesPurchaseTypeCommission:
          res?.data[0]?.vehiclesPurchaseTypeCommission,
        intermediatedVehiclesCommission:
          res?.data[0]?.intermediatedVehiclesCommission,
        sellingCommission: res?.data[0]?.sellingCommission,
        totalNegativeProfit: res?.data[0]?.totalNegativeProfit,
      });

      setSoldVehicles({
        ...soldVehicles,
        negativeProfitVehicles: res.data[0]?.negativeProfitVehicles,
        positiveProfitVehicles: res.data[0]?.positiveProfitVehicles,
        positiveProfitPage: positiveProfitPage,
        positiveProfitPerPage: positiveProfitPerPage,
        negativeProfitPage: negativeProfitPage,
        negativeProfitPerPage: negativeProfitPerPage,
        nrOfNegativeVehicles: res.data[0]?.totalNegativeVehicles,
        nrOfPositiveVehicles: res.data[0]?.totalPositiveVehicles,
      });

      const totalNetCommission =
        (res.data[0]?.vehiclesTotalCommission *
          (100 - res.data[0]?.generalTax)) /
        100;

      setTotalCommissions({
        totalBrutCommission: res.data[0]?.vehiclesTotalCommission,
        totalNetCommission: totalNetCommission.toFixed(2),
      });
    }
  }

  /**
   *
   * @returns trimesters componenet
   */
  const renderAnalysis = () => {
    return (
      <>
        <TableContainer className={style.tableContainer}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />

                <TableCell align="center">{Vocabulary.realized}</TableCell>
                <TableCell align="center">
                  {Vocabulary.target +
                    " " +
                    Vocabulary.Trimester +
                    " " +
                    currentTrimester}
                  {isAdmin && (
                    <Tooltip title={Vocabulary.edit}>
                      <ModeEditIcon
                        className={style.icon}
                        onClick={() => setEditMode(true)}
                      />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell align="center">
                  {Vocabulary.standardCommission}
                </TableCell>
                <TableCell align="center">
                  {Vocabulary.soldCommission}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, index) => {
                const target = Object.assign(trimesterData)[row?.key];
                const userSales = Object.assign(state)[row.key];
                return (
                  <TableRow key={row.key} className={style.tableRow}>
                    <TableCell component="th" scope="row">
                      {"Analiza " + row.label}
                    </TableCell>

                    <TableCell align="center" className={style.tableCell}>
                      {numberFormat.format(userSales)}
                    </TableCell>
                    <TableCell align="center">
                      {editMode ? (
                        <TextField
                          size="small"
                          value={Object.assign(trimesterData)[row?.key]}
                          variant="outlined"
                          onChange={(
                            event: ChangeEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >
                          ) => {
                            setTrimesterData({
                              ...trimesterData,
                              [row.key]: event.target.value,
                            });
                          }}
                          InputProps={{
                            inputComponent: NumericFormatCustom,
                          }}
                        />
                      ) : (
                        numberFormat.format(target)
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {commisionsProcent[row.targetCode] + " %"}
                    </TableCell>
                    <TableCell align="center">
                      {userSales > target
                        ? commisionsProcent[row.targetCode]
                        : (
                            (userSales * commisionsProcent[row.targetCode]) /
                            target
                          ).toFixed(3)}{" "}
                      %
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {editMode && (
          <Grid item md={12} className={style.center}>
            <Button
              type="button"
              variant="outlined"
              aria-label="search"
              onClick={() => setEditMode(false)}
            >
              {Vocabulary.cancel}
            </Button>
            <Button
              type="button"
              variant="contained"
              aria-label="search"
              onClick={saveUserBonuses}
            >
              {Vocabulary.save}
            </Button>
          </Grid>
        )}
      </>
    );
  };

  /**
   *
   */
  const saveUserBonuses = async () => {
    const url = `${urlEnum.userBonuses}`;
    const res = await updateData(url, {
      userData,
      userId,
      month,
      year,
      trimesterData,
    });
    if (!res) {
      return;
    } else
      setUserData({
        ...userData,
        baseNetSalary: res.data?.userBonus?.base_net_salary,
        isSubcontractor: res?.data?.userBonus?.is_subcontractor ?? false,
        leasingBonus: res?.data?.userBonus?.bonus_leasing ?? 0,
        otherBonuses: res?.data?.userBonus?.other_bonuses ?? 0,
        mealTickets: res?.data?.userBonus?.meal_tickets ?? 0,
      });
    getVehicles();
    setEditMode(false);
  };

  /**
   *
   */
  const getUserData = async () => {
    const url = `${urlEnum.userBonuses}`;
    const res = await postData(url, {
      user_id: userId,
      month: month,
      year: year,
    });

    if (!res) {
      return;
    } else {
      setUserData({
        ...userData,
        leasingBonus: res?.data?.userBonus?.bonus_leasing ?? 0,
        otherBonuses: res?.data?.userBonus?.other_bonuses ?? 0,
        mealTickets: res?.data?.userBonus?.meal_tickets ?? 0,
        baseNetSalary:
          res?.data?.userBonus?.base_net_salary ?? res?.data?.userSalary,
        isSubcontractor:
          res?.data?.userBonus?.is_subcontractor ??
          res?.data?.user?.is_subcontractor,
      });

      if (res?.data?.userBonus) {
        const userBonus = res.data?.userBonus;
        setTrimesterData({
          targetVolum: userBonus.target_volume,
          comisionProfit: userBonus.profit_commission,
          comisionCA: userBonus.CA_commission,
        });
      }
    }
  };

  /**
   *
   */
  const exportExcel = async () => {
    const element = document.getElementById("loading");
    const url = `${urlEnum.users}/exportSalary`;
    const token = localStorage.getItem("access_token");
    const type = "download";
    const user = cacheContext.cache.users.find(
      (user: UserData) => userId && user.id === parseInt(userId)
    );
    const monthName = month
      ? new Date(0, month - 1, 1).toLocaleString("ro-Ro", {
          month: "long",
        })
      : "";
    const fileName = `Salariu_${user.nume}_${monthName}_${year}`;
    if (url)
      fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, month, year, monthName, type }),
      })
        .then((response) => response.blob())
        .then((response) => {
          download(
            response,
            fileName,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
          //disable loading

          element?.classList.remove("loading");
          element?.classList.add("loadingDisabled");
        });
  };

  /**
   *
   */
  const renderYearAndMonth = () => {
    return (
      <>
        <Grid item xs={6} md={2} lg={4}>
          <Typography>{Vocabulary.yearName}</Typography>
          {desktop ? (
            <ToggleButtonGroup
              color="primary"
              value={historyData.year}
              exclusive
              onChange={(event, newValue) => {
                setHistoryData({ ...historyData, year: newValue });
                navigate(
                  `/salaries/user/${user?.id}/${historyData.month}/${newValue}`
                );
              }}
              aria-label="Platform"
            >
              {years?.map((year: string, index: number) => {
                return (
                  <ToggleButton key={index} value={year}>
                    {year}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          ) : (
            <Autocomplete
              freeSolo={false}
              fullWidth
              disablePortal
              value={historyData.year}
              isOptionEqualToValue={(option, value) => option == value}
              getOptionLabel={(option: any) => option.toString()}
              options={years}
              onChange={(event: any, newValue: any) => {
                setHistoryData({ ...historyData, year: newValue });
                navigate(
                  `/salaries/user/${user?.id}/${historyData.month}/${newValue}`
                );
              }}
              className={style.autocomplete}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
            />
          )}
        </Grid>
        <Grid item xs={6} md={10} lg={8}>
          <Typography>{Vocabulary.month}</Typography>
          {desktop ? (
            <ToggleButtonGroup
              color="primary"
              value={historyData.month}
              exclusive
              onChange={(event, newValue) => {
                setHistoryData({ ...historyData, month: newValue });
                navigate(
                  `/salaries/user/${user?.id}/${newValue}/${historyData.year}`
                );
              }}
              aria-label="Platform"
            >
              {months?.map((month, index) => {
                return (
                  <ToggleButton key={index} value={index + 1}>
                    {month}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          ) : (
            <Autocomplete
              freeSolo={false}
              fullWidth
              disablePortal
              value={months[historyData.month - 1]}
              isOptionEqualToValue={(option, value) =>
                months.indexOf(option) == months.indexOf(value)
              }
              options={months}
              onChange={(event: any, newValue: any) => {
                const index = months.indexOf(newValue);
                if (index >= 0)
                  setHistoryData({ ...historyData, month: index + 1 });
                navigate(
                  `/salaries/user/${user?.id}/${index + 1}/${historyData.year}`
                );
              }}
              className={style.autocomplete}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
            />
          )}
        </Grid>
      </>
    );
  };
  return (
    <Paper className={style.paper} elevation={3}>
      {user ? (
        <Grid item xs={12} md={12}>
          <Typography className={style.userName}>
            {Vocabulary.user + ": " + user?.nume}
          </Typography>
        </Grid>
      ) : null}
      <Grid container spacing={3}>
        {renderYearAndMonth()}
        {state.isMarketing ? (
          <VehiclesForMarketingTable
            historyData={historyData}
            numberFormat={numberFormat}
            setTotalMarketingCommission={setTotalMarketingCommission}
            location={location}
          />
        ) : (
          <VehiclesTable
            user={user}
            getVehicles={getVehicles}
            rows={rows}
            state={state}
            soldVehicles={soldVehicles}
            numberFormat={numberFormat}
            month={month}
            year={year}
            setProfitFromOtherUsers={setProfitFromOtherUsers}
            trimesterData={trimesterData}
          />
        )}

        <Grid item xs={12} sm={12}>
          {!state.isMarketing && state?.targetVolum != 0 && renderAnalysis()}
        </Grid>
        <Grid item xs={12} sm={6}>
          <SalaryTotals
            settings={settings}
            userData={userData}
            commissionsSum={totalCommissions.totalBrutCommission}
            generalTax={Number(state.generalTax)}
            sellingLogisticsCommission={state.sellingLogisticsCommission}
            intermediatedVehiclesCommission={
              state?.intermediatedVehiclesCommission
            }
            isMarketing={state.isMarketing}
            monthlyProfit={state.monthlyProfit.toFixed(2)}
            vehiclesPurchaseTypeCommission={
              state.vehiclesPurchaseTypeCommission
            }
            sellingCommission={state.sellingCommission}
            profitFromOtherUsers={profitFromOtherUsers}
            marketingCommission={totalMarketingCommission}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={style.gridItem}>
          <UserBonuses
            userData={userData}
            setUserData={setUserData}
            saveUserBonuses={saveUserBonuses}
            permission={props?.info?.accesToBonuses}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button
            className={style.downloadButton}
            variant="contained"
            onClick={() => exportExcel()}
            startIcon={<CloudDownloadIcon />}
          >
            {Vocabulary.downloadSalaryExcel}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withRole(SalaryComponent);
