import { PaymentMethods } from "../Utils/utils";
import { VehicleModel } from "./VehicleModel";
import * as Yup from "yup";
import { Vocabulary } from "../Utils/Vocabulary";

export class AutocompleteModel {
  nume: string | null = ""; //Este pus "nume" pentru ca asa e numit pe back-end si folosim aceeasi nomenclatura
}

export class AutocompleteModelTipCost {
  nume: string | null = ""; //Este pus "nume" pentru ca asa e numit pe back-end si folosim aceeasi nomenclatura
  comision: string | null = "";
  pret_recomandat: string | null = "";
}

export class AutocompleteModelForAddress {
  nume: string | null = "";
  judet: string | null = "";
  adresa: string | null = "";
  telefon: string | null = "";
}

export class AutocompleteModelForVehicleModels {
  nume: string | null = "";
  brand_id: string | null = "";
}

export class AutocompleteModelForFirmaAchizitie {
  nume_firma: string | null = "";
}

export class AutocompleteModelForTipTVA {
  nume: string | null = "";
  valoare: string | null = "";
}

export class AutocompleteModelForCosts {
  nume: string | null = "";
  tip_cost_adm: string | null = "";
}
export class AutocompleteModelForDrivers {
  name: string | null = "";
  phoneNumber: string | null = "";
}

export class NumberOfVehicleForFinancialDepartment {
  numberOfVehiclesUnpaid: number | null = null;
  numberOfVehiclesPaid: number | null = null;
  numberOfVehiclesWithAdvancePaid: number | null = null;
  numberOfVehiclesWithContractToSign: number | null = null;
  numberOfVehiclesWithContractSigned: number | null = null;
}

export class GenericAutocompleteModalModel {
  open: boolean | null = false;
  title: string | null = "";
  data: object[] | null = [];
  id: any | null = "";
  columns: object[] | null = [];
  validationSchema: any | null = [];
  hiddenFieldsArray: string[] | null = [];
  modelForFields: AutocompleteModel = new AutocompleteModel();
}

export class GenericAutocompleteModalModelForDrivers {
  open: boolean | null = false;
  title: string | null = "";
  data: object[] | null = [];
  id: any | null = "";
  columns: object[] | null = [];
  validationSchema: any | null = [];
  hiddenFieldsArray: string[] | null = [];
  modelForFields: AutocompleteModelForDrivers =
    new AutocompleteModelForDrivers(); //FOLOSES ASA CA TREBUIE SA AM NAME in LOC DE NUME
}
export class ContractInformationModel {
  [key: string]: any;
  metodaDeIncasare: string = PaymentMethods[1].nume; //transfer bancar
  metodeFinantare: Array<{
    name: string;
    value: number;
    file: string | null | undefined | File;
    open?: boolean;
  }> = [];
  pretVanzare = 0;
  avans = 0;
  observatii = "";
  isBuyBack = false;
  sumaBuyBack = 0;
  marcaBuyBack = "";
  nrInmatriculareBuyBack = "";
  sumaCreditata = 0;
  restDePlata = 0;
  verificariStareTehnica = "";
  hasContract = false;
}

export class ClientMessageModel {
  [key: string]: any;
  id = 0;
  name = "";
  email = "";
  phone = "";
  message = "";
  vehicle: VehicleModel | null = null;
  first_seen_user_id: number | null = null;
  details?: string | null;
  source?: string;
}

export class StatusModel {
  [key: string]: any;
  id? = 0;
  nume?: string | null = "";
  culoare?: string = "";
  tip_status?: string | null = "";
}

export class GenericModel {
  [key: string]: any;
  id? = 0;
  nume?: string | null = "";
}

export class ImageModel {
  vehicul_id: number | null = null;
  vin: string | null = "";
  infoFiles: Array<any> = [];
}

export class ImageModelDelete {
  infoFiles: Array<any> = [];
}

export class CompanyModel {
  nume_firma: string | null = "";
  CIF: string | null = "";
  nrRegCom: string | null = "";
  adresa: string | null = "";
  telefon: string | null = "";
  email: string | null = "";
  banca: string | null = "";
  IBAN: string | null = "";
  logoPath: any;
  nrDocs: NrDocs | null = new NrDocs();
  serieDocs: SerieDocs | null = new SerieDocs();
  delegat: Delegat | null = new Delegat();
}

export class NrDocs {
  nrProforma: number | null = 0;
  nrContract: number | null = 0;
}

export class SerieDocs {
  serieProforma: string | null = "";
  serieContract: string | null = "";
}

export class Delegat {
  nume: string | null = "";
  prenume: string | null = "";
  CNP: string | null = "";
  seria: string | null = "";
  numar: string | null = "";
}

export class Note {
  entity_id: string | null = "";
  user_id: string | null = "";
  note: string | null = "";
  entity: string | null = "";
}

export type ShippingData = {
  id?: number | undefined;
  name: string;
  address: string;
  phoneNumber: string;
  drivers: any;
  nrVehicul: any;
  nrRemorca: any;
};

export const BunuriTransportate = Yup.object().shape({
  codScopOperatiune: Yup.string().required(Vocabulary.required),
  codTarifar: Yup.string().required(Vocabulary.required),
  denumireMarfa: Yup.string().required(Vocabulary.required),
  cantitate: Yup.string().required(Vocabulary.required),
  codUnitateMasura: Yup.string().required(Vocabulary.required),
  greutateNeta: Yup.string().required(Vocabulary.required),
  greutateBruta: Yup.string().required(Vocabulary.required),
  valoareLeiFaraTva: Yup.string().required(Vocabulary.required),
});

export const PartenerComercial = Yup.object().shape({
  codTara: Yup.string().required(Vocabulary.required),
  codOrgTransport: Yup.string(),
  denumire: Yup.string().required(Vocabulary.required),
});
export const DateTransport = Yup.object().shape({
  codTara: Yup.string(),
  denumireOrgTransport: Yup.string().required(Vocabulary.required),
  codOrgTransport: Yup.string(),
  codTaraOrgTransport: Yup.string().required(Vocabulary.required),
  dataTransport: Yup.string().required(Vocabulary.required),
  nrVehicul: Yup.string()
    .required(Vocabulary.required)
    .matches(
      /^[A-Z0-9]+$/,
      "Numarul de inmatriculare este invalid. Exemplu: SV123JUD"
    )
    .min(6, `Numarul de inmatriculare trebuie sa aiba minim 6 caractere.`)
    .max(20, `Numarul de inmatriculare trebuie sa aiba maxim 20 caractere`),
  nrRemorca: Yup.string()
    .required(Vocabulary.required)
    .matches(
      /^[A-Z0-9]+$/,
      "Numarul de inmatriculare este invalid. Exemplu: SV123JUD"
    )
    .min(6, `Numarul de inmatriculare trebuie sa aiba minim 6 caractere.`)
    .max(20, `Numarul de inmatriculare trebuie sa aiba maxim 20 caractere`),
});

export const Locatie = Yup.object().shape({
  codJudet: Yup.string(),
  orasLocalitate: Yup.string(),
  denumireLocalitate: Yup.string(),
  denumireStrada: Yup.string(),
  numar: Yup.string(),
  codPostal: Yup.string(),
  alteInfo: Yup.string(),
});

export const DocumenteTransport = Yup.object().shape({
  tipDocument: Yup.string().required(Vocabulary.required),
  numarDocument: Yup.string().required(Vocabulary.required),
  dataDocument: Yup.string().required(Vocabulary.required),
  // observatii: Yup.string(),
});

export const NotificareANAF = Yup.object().shape({
  CUI: Yup.string().required(Vocabulary.required),
  bunuriTransportate: Yup.array()
    .of(BunuriTransportate)
    .required(Vocabulary.required),
  documenteTransport: Yup.array()
    .of(DocumenteTransport)
    .required(Vocabulary.required),
  partenerComercial: PartenerComercial,
  dateTransport: DateTransport,
  codPtf: Yup.object()
    .shape({
      codPtfIntrare: Yup.string(),
      codPtfIesire: Yup.string(),
    }),
    // .test("one-of-required", "Codul PTF este obligatoriu", function (value) {
    //   return !!(value.codPtfIntrare || value.codPtfIesire);
    // }),
  locatie: Yup.object()
    .shape({
      locatieIntrare: Locatie,
      locatieIesire: Locatie,
    })
    .test("one-of-required", "Locatia este obligatorie", function (value) {
      if (!value || (!value.locatieIntrare && !value.locatieIesire)) {
        return false;
      }

      // Check if at least one field is valid
      const isValidIntrare = Locatie.isValidSync(value.locatieIntrare);
      const isValidIesire = Locatie.isValidSync(value.locatieIesire);

      return isValidIntrare || isValidIesire;
    }),
  token: Yup.string(),
  tipOperatie: Yup.string(),
  tipIntrare: Yup.string().required(Vocabulary.required),
  tipIesire: Yup.string().required(Vocabulary.required),
  vinAutovehicul: Yup.string(),
  uit: Yup.string()
});

export const TransportData = Yup.object().shape({
  numarZile: Yup.number()
    .required(Vocabulary.required)
    .max(60, `Numarul maxim de zile este 60`),
  CUI: Yup.string().required(Vocabulary.required),
  token: Yup.string(),
  // observatii: Yup.string(),
});
////WHATSAPP MODEL MESSAGES

export class WhatsappDirectMessage {
  "id" = "184736571392272";
  "messaging_product" = "whatsapp";
  "recipient_type" = "individual";
  "to" = "";
  "type" = "text";
  "text" = {
    preview_url: true,
    body: "",
  };
}

export class WhatsappTemplateMessage {
  "id" = "184736571392272";
  "messaging_product" = "whatsapp";
  "to" = "+40753407139";
  "type" = "template";
  "template" = {
    name: "hello_world", //Numele trebuie sa corespunda cu numele dat din tabelul cu template-uri (Modele de mesaj) din bussines.facebook.com
    language: { code: "en_US" },
  };
}

export class CanceledClientMessageModel {
  [key: string]: any;
  messageId: number | null = 0;
  opened: boolean | null = false;
}

export class AutocompleteUser {
  "id": number;
  "nume": string;
}
